import styled from 'styled-components';

const HeaderStyles = styled.header`
  h3 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
  }
  .cred-modal-btn {
    cursor: pointer;
    border: none;
    border-width: 0;
    background: transparent;
  }
`;

export default HeaderStyles;
