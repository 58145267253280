const theme = {
  colors: {
    primary: 'rgb(109, 168, 82);',
    primaryDarker: 'rgb(65, 99, 49);',
  },
  fontSizes: {
    title: '38px',
    subtitle: '36px',
    headerCopy: '30px',
    regularLargeCopy: '20px',
    regularCopy: '18px',
    strongCopy: '15px',
    legal: '12px',
  },
  fontWeights: {
    thin: 100,
    normal: 400,
    heading: 600,
    bold: 700,
  },
};

export default theme;
