import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import HeaderStyles from './Header.styles';
import useModal from '../../../helpers/useModal';
import bbbLogo from '../../../images/bbb-aplus-logo.png';
import unitedwayLogo from '../../../images/United-Way-logo.png';
import trustpilotLogo from '../../../images/trustpilot-logo.png';
import CredibilityModal from '../../CredibilityModal/CredibilityModal';

function Header({ data, phone }) {
  const {
    logo, healine, showCredibilityLogos, unitedWayCopy, buttonCopy, bbbCopy,
  } = data;
  const { img, link } = logo;
  const { text, number } = phone;

  const { showModal, toggleModal } = useModal(false);
  const [modalBrand, setModalBrand] = useState('');

  return (
    <HeaderStyles>
      <div className="container">
        <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
        >
          <Col className="gutter-row" xs={12} sm={6} lg={5}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <img src={img} alt="debt.com logo" />
            </a>
          </Col>
          {showCredibilityLogos && (
            <Col className="gutter-row" xs={12} sm={10} lg={11}>
              <div className="credibility-logos">
                <button
                  type="button"
                  onClick={() => {
                    toggleModal();
                    setModalBrand('bbb');
                  }}
                  className="cred-modal-btn"
                >
                  <img src={bbbLogo} alt="debt.com logo" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    toggleModal();
                    setModalBrand('united-way');
                  }}
                  className="cred-modal-btn unitedModal"
                >
                  <img src={unitedwayLogo} alt="debt.com logo" />
                </button>
                <img src={trustpilotLogo} alt="debt.com logo" />
              </div>
            </Col>
          )}
          <Col className="gutter-row" xs={24} sm={8} lg={8}>
            <h3>
              <span>{healine}</span>
              <a href={`tel:+${number}`}>{text}</a>
            </h3>
          </Col>
        </Row>
      </div>
      <CredibilityModal
        bbbLogo={bbbLogo}
        modalPhone={text}
        hide={toggleModal}
        brand={modalBrand}
        bbbCopy={bbbCopy()}
        showModal={showModal}
        modalPhoneNum={number}
        buttonCopy={buttonCopy}
        unitedwayLogo={unitedwayLogo}
        unitedWayCopy={unitedWayCopy()}
      />
    </HeaderStyles>
  );
}

Header.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  phone: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Header;
