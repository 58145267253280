import React from 'react';
import PropTypes from 'prop-types';
import FooterStyles from './Footer.styles';

function Footer({ data }) {
  const { links, copyRights } = data;
  return (
    <FooterStyles>
      <div className="container">
        <ul>
          {links.map(({ copy, link }) => (
            <li key={copy}>
              <a href={link} target="_blank" rel="noopener noreferrer nofollower">
                {copy}
              </a>
            </li>
          ))}
        </ul>
        {copyRights()}
      </div>
    </FooterStyles>
  );
}

Footer.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Footer;
