import styled from 'styled-components';

const DisclaimerStyles = styled.div`
  padding: 50px 0;
  background-color: #e9e9e9;
  font-size: 0.8em;
  padding: 25px 0;
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  strong {
    color: #000;
  }
  @media all and (max-width: 767px) {
    padding: 30px 0;
    text-align: center;
  }
`;

export default DisclaimerStyles;
