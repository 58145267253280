import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import {
  faLifeRing,
  faBullhorn,
  faMoneyBillAlt,
  faMapMarkedAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeaturesStyles from './Features.styles';


const Features = ({ data }) => {
  const { headline, helpOptions, accessBtn } = data;
  return (
    <FeaturesStyles>
      <div className="content-section features-section">
        <div className="container">
          <h2>
            {headline}
          </h2>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            justify="center"
          >
            <Col className="gutter-row" sm={10} md={7}>
              <FontAwesomeIcon icon={faLifeRing} size="2x" />
              <h3>{helpOptions[0].copy}</h3>
            </Col>
            <Col className="gutter-row" sm={10} md={7}>
              <FontAwesomeIcon icon={faBullhorn} size="2x" />
              <h3>{helpOptions[1].copy}</h3>
            </Col>
          </Row>
          <Row
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
            justify="center"
          >
            <Col className="gutter-row" sm={10} md={7}>
              <FontAwesomeIcon icon={faMoneyBillAlt} size="2x" />
              <h3>{helpOptions[2].copy}</h3>
            </Col>
            <Col className="gutter-row" sm={10} md={7}>
              <FontAwesomeIcon icon={faMapMarkedAlt} size="2x" />
              <h3>{helpOptions[3].copy}</h3>
            </Col>
          </Row>
          <a
            href={accessBtn.link}
            target="_blank"
            rel="noopener noreferrer nofollower"
          >
            <Button type="primary" size="large" className="form-btn">
              {accessBtn.copy}
            </Button>
          </a>
        </div>
      </div>
    </FeaturesStyles>
  );
};

Features.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Features;
