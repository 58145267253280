import styled from 'styled-components';
import HeroImage from '../../../images/hero-image-helpline.jpg';

const HeroStyles = styled.div`
  .hero-container {
    text-align: center;
    background: linear-gradient(rgba(0, 117, 137, 0.85), rgba(0, 117, 137, 0.85)), url(${HeroImage});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 35px 0 75px;
    color: white;
    h1 {
      color: white;
      font-size: 2.5em;
      font-weight: 100;
      line-height: initial;
      font-family: 'Open Sans', sans-serif;
      p {
        display: block;
        font-weight: 600;
      }
    }
    .hero-content-box {
      border-width: 1px 1px 0px;
      border-right-style: solid;
      border-top-style: solid;
      border-left-style: solid;
      border-right-color: white;
      border-top-color: white;
      border-left-color: white;
      border-image: initial;
      position: relative;
      padding: 35px;
      border-bottom-style: initial;
      border-bottom-color: initial;
      width: 60%;
      margin: 0 auto;
      transition: all 0.4s ease;
      h2 {
        color: white;
        font-weight: 300;
        font-size: 1.8em;
        font-family: 'Open Sans', sans-serif;
        p {
          font-weight: 600;
          display: block;
        }
      }
      h3 {
        position: absolute;
        font-size: 1.75em;
        font-weight: 100;
        bottom: -38px;
        width: 100%;
        margin-left: -35px;
        color: white;
        font-family: 'Open Sans', sans-serif;
        a {
          display: inline-block;
          background-color: white;
          padding: 3px 20px;
          color: #44accd;
          span {
            font-weight: 600;
          }
        }
        &::before {
          content: '';
          display: block;
          border-top: 1px solid white;
          width: 15%;
          height: 1px;
          position: absolute;
          top: 50%;
          z-index: 1;
        }
        &::after {
          content: '';
          display: block;
          border-top: 1px solid white;
          width: 15%;
          height: 1px;
          position: absolute;
          top: 50%;
          z-index: 1;
          right: 0px;
        }
      }
    }
  }
  @media (max-width: 1140px) {
    .hero-container {
      .hero-content-box {
        width: 65%;
      }
    }
  }
  @media (max-width: 1000px) {
    .hero-container {
      .hero-content-box {
        width: 70%;
        h3 {
          &::before {
            width: 20%;
          }
          &::after {
            width: 20%;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .hero-container {
      .hero-content-box {
        width: 75%;
        h3 {
          &::before {
            width: 17%;
          }
          &::after {
            width: 17%;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    .hero-container {
      .hero-content-box {
        width: 90%;
        h3 {
          bottom: -29px;
        }
      }
    }
  }
  @media (max-width: 575px) {
    .hero-container {
      h1 {
        font-size: 2.1em;
      }
      .hero-content-box {
        width: 100%;
        padding: 20px 20px 25px;
        h2 {
          font-size: 1.5em;
        }
        h3 {
          margin-left: -20px;
          font-size: 1.5em;
          bottom: -30px;
          &:before {
            width: 7%;
          }
          &:after {
            width: 7%;
          }
        }
      }
    }
  }
`;

export default HeroStyles;
