import styled from 'styled-components';
import ContentImgBkg from '../../../images/us-flag.jpg';

const DcomHotlineStyles = styled.div`
  font-family: 'Open Sans';
  font-weight: 100;
  color: #2a353d;
  font-size: 16px;
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .ant-btn-primary {
    background-color: #6da852;
    border: none;
  }
  header {
    padding: 20px 0;
    .credibility-logos {
      margin-top: 20px;
      img {
        max-width: 90px;
        max-height: 55px;
        margin-right: 15px;
      }
    }
    h3 {
      text-align: right;
      font-size: 1.2em;
      line-height: initial;
      margin-bottom: 0;
      margin-top: 10px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      span {
        display: block;
      }
      a {
        font-size: 1.5em;
        font-weight: 600;
        color: #44accd;
      }
    }
  }

  .content-section {
    padding: 50px 0;
    &.form-section-grey {
      h3 {
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 20px;
      }
    }
    &.gray {
      background-color: #e9e9e9;
    }
    .ant-col:first-of-type {
      border-right: 1px solid #d4d4d4;
    }
    p {
      font-size: 16px;
      font-weight: 100;
      font-family: 'Open Sans', sans-serif;
    }
    h4 {
      font-size: 16px;
      line-height: 1.5715;
      font-weight: 100;
      font-family: 'Open Sans', sans-serif;
    }
    a {
      font-size: 18px;
      color: #44accd;
      font-weight: 600;
      font-family: 'Open Sans', sans-serif;
    }
    .credibility-logos {
      margin-top: 20px;
      img {
        max-width: 90px;
        max-height: 55px;
        margin-right: 15px;
      }
    }
    .numbered-list {
      .ant-col {
        border-right: none !important;
      }
      p {
        padding: 11px 0px;
        font-size: 1.1em;
      }
      span.number-circle {
        text-align: center;
        border: 2px solid #44accd;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: block;
        line-height: 46px;
        font-size: 1.4em;
      }
    }
    &.features-section {
      text-align: center;
      h2 {
        width: 65%;
        margin: 0 auto 20px;
        font-family: 'Open Sans', sans-serif;
        font-size: 1.5em;
        font-weight: 400;
      }
      .ant-col {
        border: none !important;
        h3 {
          font-family: 'Open Sans', sans-serif;
          font-size: 1.17em;
          font-weight: 400;
        }
        .svg-inline--fa {
          font-size: 2.8em;
          color: #44accd;
          margin: 25px 0 12px;
        }
      }
      .ant-btn-primary {
        margin-top: 35px;
      }
      .form-btn {
        min-width: 80px;
        color: #fff;
        background-color: #6da852;
        transition: all 250ms ease;
        &:hover {
          background-color: #416331;
        }
      }
    }
    &.image-bkg {
      background: linear-gradient(rgba(0, 117, 137, 0.85), rgba(0, 117, 137, 0.85)),
        url(${ContentImgBkg});
      background-size: cover;
      background-repeat: no-repeat;
      p {
        width: 65%;
        margin: 0 auto;
        color: white;
        font-size: 1.3em;
        text-align: center;
      }
    }
    &.leader-quotes {
      .quote-bubble {
        background-color: #44accd;
        border-radius: 4px;
        padding: 20px;
        color: white;
        position: relative;
        min-height: 240px;
        cite {
          font-style: italic;
          font-weight: 600;
          text-align: right;
          display: block;
          font-size: 15px;
        }
        &:after {
          content: ' ';
          position: absolute;
          left: 50%;
          bottom: -15px;
          border-top: 15px solid #44accd;
          border-right: 15px solid transparent;
          border-left: 15px solid transparent;
          border-bottom: none;
          margin-left: -15px;
        }
      }
      .circle-img {
        border-radius: 50%;
        display: block;
        width: 125px;
        margin: 35px auto 0;
      }
      .ant-col {
        border: none !important;
      }
    }
  }
  footer {
    background-color: #2a353d;
    padding: 30px 0;
    text-align: center;
    color: white;
    ul {
      margin: 0;
      li {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 15px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          color: white;
          &:hover {
            color: #44accd;
          }
        }
      }
    }
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  @media (max-width: 1140px) {
    .hero-container {
      .hero-content-box {
        width: 65%;
      }
    }
    .content-section {
      &.leader-quotes {
        .quote-bubble {
          min-height: 260px;
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .hero-container {
      .hero-content-box {
        width: 70%;
        h3 {
          &::before {
            width: 20%;
          }
          &::after {
            width: 20%;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .hero-container {
      .hero-content-box {
        width: 75%;
        h3 {
          &::before {
            width: 17%;
          }
          &::after {
            width: 17%;
          }
        }
      }
    }
  }
  @media (max-width: 950px) {
    .content-section {
      &.leader-quotes {
        .quote-bubble {
          min-height: 300px;
        }
      }
    }
  }
  @media (max-width: 800px) {
    header {
      .credibility-logos {
        img {
          max-width: 80px;
          max-height: 45px;
          margin-right: 12px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    font-size: 14px;
    header {
      padding: 15px 0;
    }
    .hero-container {
      .hero-content-box {
        width: 90%;
        h3 {
          bottom: -33px;
        }
      }
    }
    .content-section {
      padding: 30px 0;
      text-align: center;
      .ant-col:first-of-type {
        border-right: none;
      }
      .numbered-list {
        span.number-circle {
          margin: 0 auto;
        }
      }
      &.features-section {
        h2 {
          width: 90%;
        }
      }
      &.image-bkg {
        p {
          width: 90%;
        }
      }
    }
  }
  @media (max-width: 670px) {
    header {
      .credibility-logos {
        margin-top: 15px;

        img {
          max-width: 70px;
          max-height: 35px;
          margin-right: 10px;
        }
      }
      h3 {
        margin-top: 8px;
        font-size: 1em;

        a {
          font-size: 1.4em;
        }
      }
    }
  }
  @media (max-width: 575px) {
    header {
      text-align: center;
      .credibility-logos {
        margin-top: 30px;
        img {
          max-width: 75px;
          max-height: 40px;
        }
      }
      h3 {
        font-size: 1.3em;
        text-align: center;
        margin-top: 20px;
        a {
          font-size: 1.4em;
        }
      }
    }
    .hero-container {
      h1 {
        font-size: 2.1em;
      }
      .hero-content-box {
        width: 100%;
        padding: 20px 20px 25px;
        h2 {
          font-size: 1.5em;
        }
        h3 {
          margin-left: -20px;
          bottom: -30px;
          &::before {
            width: 7%;
          }
          &::after {
            width: 7%;
          }
        }
      }
    }
    .content-section {
      &.leader-quotes {
        .quote-bubble {
          min-height: initial;
        }
        .circle-img {
          margin: 35px auto 35px;
        }
        .ant-col {
          border: none !important;
        }
      }
    }
  }
  @media (max-width: 470px) {
    header {
      .credibility-logos {
        margin-top: 15px;
      }
    }
  }
  @media (max-width: 350px) {
    header {
      .credibility-logos {
        margin-top: 10px;
        img {
          max-width: 70px;
          max-height: 35px;
        }
      }
    }
  }
`;
export default DcomHotlineStyles;
