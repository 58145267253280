import React from 'react';
import PropTypes from 'prop-types';
import DisclaimerStyles from './Disclaimer.styles';

function Disclaimer({ lang }) {
  return (
    <DisclaimerStyles>
      {lang === 'en' ? (
        <div className="container">
          <p>
            <strong>Disclaimer:</strong>
            {' '}
            The information on this website, including the guidance of
            its ask-the-expert columns, may not be helpful to everyone and it is not intended as a
            substitute for personal professional financial advice. The content is wide ranging and
            does not consider your own personal financial situation.
          </p>

          <p>
            Debt.com recommends that you seek the advice of an adviser that is fully familiar with
            your unique individual needs and circumstances before making or deploying any financial
            decisions or strategy. Please remember that your use of this website is governed by the
            Debt.com
            {' '}
            <a
              href="https://www.debt.com/terms-conditions/"
              target="_blank"
              rel="noopener noreferrer nofollower"
            >
              Terms &amp; Conditions.
            </a>
          </p>
        </div>
      ) : (
        <div className="container">
          <p>
            <strong>Aviso legal: </strong>
            La información de este sitio web, incluida la orientación de sus columnas de preguntas
            al experto, puede no ser útil para todos y no pretende ser un sustituto del
            asesoramiento financiero profesional personal. El contenido es amplio y no considera su
            propia situación financiera personal.
          </p>
          <p>
            Debt.com recomienda que busque el asesoramiento de un asesor que esté completamente
            familiarizado con sus necesidades y circunstancias individuales únicas antes de tomar o
            implementar cualquier decisión o estrategia financiera. Recuerde que el uso de este
            sitio web se rige por los Términos y condiciones de Debt.com.
            {' '}
            <a
              href="https://www.debt.com/es/terminos-y-condiciones/"
              target="_blank"
              rel="noopener noreferrer nofollower"
            >
              Términos &amp; Condiciones.
            </a>
          </p>
        </div>
      )}
    </DisclaimerStyles>
  );
}

Disclaimer.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Disclaimer;
