import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

const Leaders = ({ data }) => (
  <div className="content-section leader-quotes">
    <div className="container">
      <Row
        gutter={{
          xs: 8,
          sm: 16,
          md: 24,
          lg: 32,
        }}
        justify="center"
      >
        {data.map(({
          id, quote, sign, avatar,
        }) => (
          <Col className="gutter-row" xs={24} sm={9} key={id}>
            <div className="quote-bubble">
              <p>{quote}</p>
              <cite>{sign}</cite>
            </div>
            <img src={avatar} alt={sign} className="circle-img" />
          </Col>
        ))}
      </Row>
    </div>
  </div>
);

Leaders.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Leaders;
