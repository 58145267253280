import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import StepsStyles from './Steps.styles';

const Steps = ({ data }) => {
  const { healine, paragraph, stepsList } = data;
  return (
    <StepsStyles>
      <h3>{healine}</h3>
      <p>{paragraph}</p>
      <div className="numbered-list">
        {stepsList.map(({ id, number, copy }) => (
          <Row
            key={id}
            gutter={{
              xs: 8,
              sm: 16,
              md: 32,
              lg: 32,
            }}
          >
            <Col className="gutter-row" xs={24} md={4} lg={3}>
              <span className="number-circle">{number}</span>
            </Col>
            <Col className="gutter-row" xs={24} md={20} lg={21}>
              <p>{copy}</p>
            </Col>
          </Row>
        ))}
      </div>
    </StepsStyles>
  );
};

Steps.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Steps;
