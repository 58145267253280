import React from 'react';
import PropTypes from 'prop-types';


const Specialized = ({ data }) => (
  <div className="content-section image-bkg">
    <div className="container">
      <p>
        {data}
      </p>
    </div>
  </div>
);

Specialized.propTypes = {
  data: PropTypes.string.isRequired,
};

export default Specialized;
