import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import ContentSectionStyles from './ContentSection.styles';
import bbbLogo from '../../../images/bbb-aplus-logo.png';
import unitedwayLogo from '../../../images/United-Way-logo.png';
import trustpilotLogo from '../../../images/trustpilot-logo.png';

const ContentSection = ({ data, phone }) => {
  const { leftParagraph, rightParagraph, generatephone } = data;
  return (
    <ContentSectionStyles>
      <div className="content-section">
        <div className="container">
          <Row
            gutter={{
              xs: 24,
              sm: 32,
              md: 32,
              lg: 32,
            }}
          >
            <Col className="gutter-row" xs={24} md={12}>
              <p>{leftParagraph}</p>
            </Col>
            <Col className="gutter-row" xs={24} md={12}>
              <h4>{rightParagraph}</h4>
              {generatephone(phone)}
              <div className="credibility-logos">
                <img src={bbbLogo} alt="debt.com logo" />
                <img src={unitedwayLogo} alt="debt.com logo" />
                <img src={trustpilotLogo} alt="debt.com logo" />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </ContentSectionStyles>
  );
};

ContentSection.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  phone: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ContentSection;
