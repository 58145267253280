import React from 'react';
import PropTypes from 'prop-types';
import HeroStyles from './Hero.styles';

const HeroSection = ({ data, phone }) => {
  const {
    lineOne, lineTwo, lineThree, lineFour, generatephone,
  } = data;
  return (
    <HeroStyles>
      <div className="hero-container">
        <div className="container">
          <h1>
            {lineOne}
            <p>{lineTwo}</p>
          </h1>
          <div className="hero-content-box">
            <h2>
              {lineThree}
              <p>{lineFour}</p>
            </h2>
            {generatephone(phone)}
          </div>
        </div>
      </div>
    </HeroStyles>
  );
};

HeroSection.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  phone: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default HeroSection;
