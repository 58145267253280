import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterStyles = styled(Footer)`
  padding: 0;
  background-color: #2a353d;
  text-align: center;
  color: white;
  ul {
    padding-left: 0;
    margin: 0;
    li {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 15px;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        color: white;
        &:hover {
          color: #44accd;
        }
      }
    }
  }
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export default FooterStyles;
