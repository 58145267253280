import React from 'react';
import enLogo from '../images/dcomLogo.png';
import esLogo from '../images/logo-es.png';
import fbImg from '../images/facebook-social-helpline.jpg';
import twImg from '../images/twitter-social-helpline.jpg';
import Don from '../images/don.jpg';
import Howard from '../images/howard.jpg';

const CONTENT = {
  en: {
    seo: {
      title: 'Free support is available through Debt.com’s Financial Hotline',
      description:
        'Debt.com is providing a free service where you can connect with experts who can answer questions about your budget, debt, credit, mortgage, and taxes, as well as identify resources to help you get through this crisis.',
      creator: '@debtcom',
      site_name: 'Debt.com',
      url: 'https://helpline.debt.com/',
      fbImg,
      twImg,
      card: 'summary_large_image',
    },
    header: {
      logo: { img: enLogo, link: 'https://debt.com/' },
      healine: 'Ask Your Question Now!',
      showCredibilityLogos: true,
      unitedWayCopy: () => (
        <p className="modal-copy">
          &#34;We are proud to partner with Debt.com&#34; Kathleen Cannon, President & CEO of United
          Way of Broward County. Debt.com supports the mission of the United Way and is a proud
          member of the United Way Chairman&#39;s Circle Organization.
        </p>
      ),
      bbbCopy: () => (
        <p className="modal-copy">
          Debt.com has been a BBB Accredited Business since 2015 and has a current A+ rating. Call
          us today and see what we can do for you.
          <a
            href="https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459"
            rel="noreferrer"
            target="_blank"
          >
            View the debt.com BBB review status.
          </a>
        </p>
      ),
      buttonCopy: 'Get Answers',
    },
    hero: {
      lineOne: 'Times are tough.',
      lineTwo: 'We’re making them easier.',
      lineThree: 'Free support is available through',
      lineFour: 'Debt.com’s Financial Hotline',
      generatephone: (realPhone) => (
        <h3>
          <a href={`tel:+${realPhone.number}`}>
            Call
            <span>{` ${realPhone.text} `}</span>
            now
          </a>
        </h3>
      ),
    },
    contentSection: {
      leftParagraph:
        'In the face of so much uncertainty, we want to make sure that you can quickly and easily access any information you may need. Our financial experts are ready to answer your questions and direct you to vital resources. We’ve also made special arrangements with all our trusted service providers to offer free guidance and advice through our toll-free hotline.',
      rightParagraph:
        'Use this free hotline to connect with experts who can answer questions about your debt, budget, mortgage, taxes, and credit.',
      generatephone: (realPhone) => (
        <h3>
          <a href={`tel:+${realPhone.number}`}>
            Call
            <span>{` ${realPhone.text} `}</span>
            to get help now
          </a>
        </h3>
      ),
    },
    steps: {
      healine: 'Connecting you with the right experts to answer your financial questions',
      paragraph:
        'This crisis has affected nearly every aspect of people’s financial lives. Debt.com will connect you with our network of accredited financial experts, who can give you the support you need to get through it.',
      stepsList: [
        { id: 'step_one', number: '1', copy: 'Tell us what you have questions about' },
        { id: 'step_two', number: '2', copy: 'Get matched with an expert in that field' },
        { id: 'step_three', number: '3', copy: 'Receive free guidance and information' },
      ],
    },
    form: {
      headline: 'What do you have questions about?',
      topic: {
        label: 'Topic',
        options: [
          {
            id: 'credit_card_debt',
            value: 'Credit Card Debt',
            text: 'Credit Card Debt',
            isDefault: true,
          },
          {
            id: 'budget_questions',
            value: 'Financial Planning',
            text: 'Budget questions',
            isDefault: false,
          },
          {
            id: 'mortgage',
            value: 'Other',
            text: 'Mortgage',
            isDefault: false,
          },
          {
            id: 'taxes',
            value: 'Tax Debt',
            text: 'Taxes',
            isDefault: false,
          },
          {
            id: 'student_loans',
            value: 'Student Loan Debt',
            text: 'Student loans',
            isDefault: false,
          },
          {
            id: 'credit_profile',
            value: 'Credit Repair',
            text: 'Credit reports and scores',
            isDefault: false,
          },
        ],
        error: 'Please select a topic.',
      },
      questions: {
        label: 'What do you have questions about?',
        errorRequired: 'Please enter your question.',
        otherError: '',
      },
      nextBtn: 'Next',
      name: {
        label: 'First Name',
        errorRequired: 'Please enter your first name.',
        otherError: 'Please enter a valid first name.',
      },
      lastname: {
        label: 'Last Name',
        errorRequired: 'Please enter your last name.',
        otherError: 'Please enter a valid last name.',
      },
      email: {
        label: 'Email Address',
        errorRequired: 'Please enter your email address.',
        otherError: 'Please enter a valid email address.',
      },
      zipField: {
        label: 'Zip Code',
        errorRequired: 'Please enter your zip code.',
        otherError: 'Please enter a valid zip code.',
      },
      phoneField: {
        label: 'Phone Number',
        errorRequired: 'Please enter your phone number.',
        otherError: 'Please enter a valid phone number.',
      },
      backBtn: 'Back',
      submitBtn: 'Submit',
      successMsg: (realPhone) => (
        <div className="final-message">
          <h3>Thanks for submitting your questions.</h3>
          <p>
            We’re already working to get you the answers you need as quickly as possible. We’ve sent
            your questions to an expert who will be in touch soon to help you find the right
            information and resources to move forward. If you need immediate help, please call
            <a href={`tel:+${realPhone.number}`}>
              <strong>{` ${realPhone.text} `}</strong>
            </a>
            to speak with someone directly so you can get support now.
          </p>
        </div>
      ),
      errorMsg: (realPhone) => (
        <>
          <h3>We’re sorry! Something went wrong.</h3>
          <p>
            We were not able to process your questions, but we want to ensure you can get the help
            you need without delay. You can try reloading this page to submit your questions again
            or you can call us at
            <a href={`tel:+${realPhone.number}`}>
              <strong>{` ${realPhone.text} `}</strong>
            </a>
            to speak with an expert directly over the phone.
          </p>
        </>
      ),
    },
    features: {
      headline:
        'During this financial crisis, we’re striving to provide the latest information on:',
      helpOptions: [
        { id: 'help_1', copy: 'Federal and state government relief efforts' },
        { id: 'help_2', copy: 'Support announcements from banks and creditors' },
        { id: 'help_3', copy: 'Advice for budgeting, unemployment and income loss' },
        { id: 'help_4', copy: 'Guidance on crafting a financial recovery plan' },
      ],
      accessBtn: {
        copy: 'Access Our Resource Center Now',
        link: 'https://www.debt.com/covid-19/',
      },
    },
    specialized:
      'Debt.com specializes in helping people connect with the right information, services, and tools to lead financially healthy lives. We work with a network of accredited financial service providers who offer a range of expertise and support for consumers who are facing financial challenges.',
    leaders: [
      {
        id: 'president',
        quote:
          '“At Debt.com, we know that people are facing immense financial challenges now and information isn’t always easy to come by. Now more than ever, we want to make sure that you can get the help you need and answers to your questions from sources you can trust.”',
        sign: 'Don Silvestri, President of Debt.com',
        avatar: Don,
      },
      {
        id: 'chairman',
        quote:
          '“Even if you don’t need help, you can help your clients, employees, and people around you get the support they need, by letting them know about this free hotline they can use.”',
        sign: 'Howard Dvorkin, Chairman of Debt.com',
        avatar: Howard,
      },
    ],
    footer: {
      links: [
        {
          copy: 'Privacy Policy',
          link: 'https://www.debt.com/privacy-policy/',
        },
        {
          copy: 'Terms & Conditions',
          link: 'https://www.debt.com/terms-conditions/',
        },
        {
          copy: 'Advertising Disclosures',
          link: 'https://www.debt.com/advertising-disclosures/',
        },
        {
          copy: 'California – Do Not Sell My Personal Information',
          link: 'https://www.debt.com/do-not-sell-my-personal-information/',
        },
      ],
      copyRights: () => <p>© 2020 Debt.com ®, LLC. All Rights Reserved.</p>,
    },
  },
  es: {
    seo: {
      title: 'Asistencia gratuita  a través de la Línea directa de ayuda de Debt.com',
      description: 'Asistencia gratuita  a través de la Línea directa de ayuda de Debt.com',
      creator: '@debtcom',
      site_name: 'Debt.com',
      url: 'https://helpline.debt.com/es',
      fbImg,
      twImg,
      card: 'summary_large_image',
    },
    header: {
      logo: { img: esLogo, link: 'https://debt.com/es' },
      healine: '¡Haga su pregunta ahora!',
      showCredibilityLogos: true,
      unitedWayCopy: () => (
        <p className="modal-copy">
          Estamos orgullosos de estar asociados con Debt.com” Kathleen Cannon, Presidente y CEO de
          United Way del condado de Broward. Debt.com apoya la misión de United Way y se complace en
          ser una organización miembro del círculo del presidente de United Way.
        </p>
      ),
      bbbCopy: () => (
        <p className="modal-copy">
          Debt.com ha sido un miembro acreditado del BBB desde el 2015 y posee una calificación A+.
          Llame hoy y vea que podemos hacer por usted.
          <a
            href=" https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459"
            rel="noreferrer"
            target="_blank"
          >
            Vea el estatus de la calificación del BBB
          </a>
        </p>
      ),
      buttonCopy: 'Comience Hoy',
    },
    hero: {
      lineOne: 'Son tiempos difíciles',
      lineTwo: 'Estamos aquí para ayudarte',
      lineThree: 'Asistencia gratuita disponible a través de la',
      lineFour: 'Línea directa de ayuda de Debt.com',
      generatephone: (realPhone) => (
        <h3>
          <a href={`tel:+${realPhone.number}`}>
            Llame hoy al
            <span>{` ${realPhone.text}`}</span>
          </a>
        </h3>
      ),
    },
    contentSection: {
      leftParagraph:
        'Ante tanta incertidumbre, queremos asegurarnos de que pueda acceder rápida y fácilmente a cualquier información que pueda necesitar. Nuestro equipo de expertos financieros está listo para responder sus preguntas y dirigirlo a recursos vitales. También hemos hecho arreglos especiales con todos nuestros proveedores de servicios de confianza para ofrecer orientación y asesoramiento gratuitos a través de nuestra línea directa.',
      rightParagraph:
        'Use esta línea directa gratuita para conectarse con expertos que pueden responder preguntas sobre su deuda, presupuesto, hipoteca, impuestos y crédito.',
      generatephone: (realPhone) => (
        <h3>
          <a href={`tel:+${realPhone.number}`}>
            Llame al
            <span>{` ${realPhone.text} `}</span>
            para obtener ayuda ahora
          </a>
        </h3>
      ),
    },
    steps: {
      healine:
        'Lo conectaremos con los expertos adecuados para responder sus preguntas financieras',
      paragraph:
        'Esta crisis ha afectado a casi todos los aspectos de la vida financiera de las personas. Debt.com lo conectará con nuestra red de expertos financieros acreditados, quienes pueden brindarle el apoyo que necesita.',
      stepsList: [
        { id: 'step_one', number: '1', copy: 'Cuéntanos qué preguntas tienes' },
        { id: 'step_two', number: '2', copy: 'Lo conectaremos con un experto en ese campo' },
        { id: 'step_three', number: '3', copy: 'Reciba orientación e información gratis' },
      ],
    },
    form: {
      headline: '¿Sobre cuáles temas tienes preguntas?',
      topic: {
        label: 'Tema',
        options: [
          {
            id: 'credit_card_debt',
            value: 'Credit Card Debt',
            text: 'Tarjetas de Crédito',
            isDefault: true,
          },
          {
            id: 'budget_questions',
            value: 'Financial Planning',
            text: 'Preguntas de Presupuesto',
            isDefault: false,
          },
          {
            id: 'mortgage',
            value: 'Other',
            text: 'Hipoteca',
            isDefault: false,
          },
          {
            id: 'taxes',
            value: 'Tax Debt',
            text: 'Impuestos',
            isDefault: false,
          },
          {
            id: 'student_loans',
            value: 'Student Loan Debt',
            text: 'Préstamos Estudiantiles',
            isDefault: false,
          },
          {
            id: 'credit_profile',
            value: 'Credit Repair',
            text: 'Puntaje y Reportes de Crédito',
            isDefault: false,
          },
        ],
      },
      questions: {
        label: 'Tus preguntas',
        errorRequired: 'Por favor entre su pregunta',
        otherError: '',
      },
      nextBtn: 'Siguiente',
      name: {
        label: 'Nombre',
        errorRequired: 'Por favor entre su nombre',
        otherError: 'Por favor entre un nombre válido',
      },
      lastname: {
        label: 'Apellido',
        errorRequired: 'Por favor entre su apellido',
        otherError: 'Por favor entre un apellido válido',
      },
      email: {
        label: 'Correo Electrónico',
        errorRequired: 'Por favor entre su correo electrónico',
        otherError: 'Por favor entre un correo electrónico válido',
      },
      zipField: {
        label: 'Código Postal',
        errorRequired: 'Por favor entre su código postal',
        otherError: 'Por favor entre un código postal válido',
      },
      phoneField: {
        label: 'Teléfono',
        errorRequired: 'Por favor entre su teléfono',
        otherError: 'Por favor entre un teléfono válido',
      },
      successMsg: (realPhone) => (
        <div className="final-message">
          <h3>Gracias por enviarnos sus preguntas</h3>
          <p>
            Nosotros estamos trabajando para ofrecerte las respuestas que necesitas lo más rápido
            posible. Hemos enviado tus preguntas a un experto quien se pondrá en contacto contigo
            para ayudarte a encontrar la información y recursos adecuados para que puedas avanzar.
            Si necesitas ayuda de inmediato, por favor llámanos al
            <a href={`tel:+${realPhone.number}`}>
              <strong>{` ${realPhone.text} `}</strong>
            </a>
            para hablar directamente con alguien y puedas obtener ayuda ahora mismo.
          </p>
        </div>
      ),
      errorMsg: (realPhone) => (
        <>
          <h3>¡Lo sentimos! Algo no está bien.</h3>
          <p>
            Nosotros no podemos procesar sus preguntas, pero queremos asegurarnos de que obtengas la
            ayuda que necesitas sin demoras. Puedes tratar de volver a cargar esta página y enviar
            tus preguntas otra vez o llamarnos al
            <a href={`tel:+${realPhone.number}`}>
              <strong>{` ${realPhone.text} `}</strong>
            </a>
            .
          </p>
        </>
      ),
      backBtn: 'Regresar',
      submitBtn: 'Enviar',
    },
    features: {
      headline:
        'Durante esta crisis económica, nos esforzamos por proporcionar la información más reciente sobre:',
      helpOptions: [
        { id: 'help_1', copy: 'Recursos de ayuda del gobierno federal y estatal' },
        { id: 'help_2', copy: 'Anuncios de ayuda de bancos y acreedores' },
        { id: 'help_3', copy: 'Asesoramiento para presupuestos, desempleo y pérdida de ingresos' },
        {
          id: 'help_4',
          copy: 'Guías para crear un plan de recuperación financiera',
        },
      ],
      accessBtn: {
        copy: 'Entre a nuestro centro de recursos',
        link: 'https://www.debt.com/es/covid-19/',
      },
    },
    specialized:
      'Debt.com se especializa en ayudar a las personas a conectarse con la información, los servicios y las herramientas adecuadas para llevar una vida financieramente saludable. Trabajamos con una red de proveedores de servicios financieros acreditados que ofrecen una amplia gama de experiencia y apoyo para los consumidores que enfrentan desafíos financieros',
    leaders: [
      {
        id: 'president',
        quote:
          '"En Debt.com, sabemos que las personas enfrentan enormes desafíos financieros ahora y que la información no siempre es fácil de encontrar. Ahora más que nunca, queremos asegurarnos de que pueda obtener la ayuda que necesita y las respuestas a sus preguntas de fuentes en las que pueda confiar."',
        sign: 'Don Silvestri, Presidente de Debt.com',
        avatar: Don,
      },
      {
        id: 'chairman',
        quote:
          '"Incluso si no necesita ayuda, puede ayudar a sus clientes, empleados y personas a su alrededor a obtener el apoyo que necesitan, haciéndoles saber acerca de esta línea directa gratuita que pueden usar."',
        sign: 'Howard Dvorkin, Chairman de Debt.com',
        avatar: Howard,
      },
    ],
    footer: {
      links: [
        {
          copy: 'Politicas de Privacidad',
          link: 'https://www.debt.com/es/politicas-de-privacidad/',
        },
        {
          copy: 'Términos y Condiciones',
          link: 'https://www.debt.com/es/terminos-y-condiciones/',
        },
        {
          copy: 'Advertising Disclosures',
          link: 'https://www.debt.com/es/divulgaciones-publicitarias/',
        },
        {
          copy: 'California – Do Not Sell My Personal Information',
          link: 'https://www.debt.com/do-not-sell-my-personal-information/',
        },
      ],
      copyRights: () => <p>© 2020 Debt.com/es-us ®, LLC. Todos los Derechos Reservados.</p>,
    },
  },
};

export default CONTENT;
